import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import { RefreshButton, ScreenTitle } from "../../components/editComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import XeroIntegrationTab from "./XeroIntegrationTab";
import ImportXeroCustomers from "./importXeroData/ImportXeroCustomers";
import ImportXeroSuppliers from "./importXeroData/ImportXeroSuppliers";
import ImportXeroProducts from "./importXeroData/ImportXeroProducts";
import { importXeroDataTaskListRequest } from "../../actions/xeroActions";
import {
  ErrorMessage,
  ImportXeroDataErrors,
  Loader,
  SubTitle,
  Tag,
  ViewDateWithTime,
} from "../../components/viewComponents";
import Pagination from "../../components/Pagination";

export default function XeroImportScreen() {
  const dispatch = useDispatch();
  useDocumentTitle("Xero Integration");

  const { loading, xeroImportTasks, error, success } = useSelector(
    (state) => state.xeroImportDataTaskList
  );
  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [updateTaskCnt, setUpdateTaskCnt] = useState(0);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    dispatch(importXeroDataTaskListRequest(pageNum, pageSize));
  }, [dispatch, pageNum, updateTaskCnt]);

  const refreshData = () => {
    setUpdateTaskCnt(updateTaskCnt + 1);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Xero Integration" />
      <ScreenTitle title="Xero Integration" />
      <div className={commonStyle.tabsContainer}>
        <XeroIntegrationTab tabName="import" />
        <div className={commonStyle.settingsContent}>
     
          <div>
            <div className="bg-lightbg p-4 rounded mt-4">
              <p>
                *You can import product, customers, suppliers data from your
                Xero system to StockUnify.
              </p>
              <div className="flex flex-row">
                <ImportXeroCustomers onTaskSuccess={refreshData}/>
                <div className="mx-8">
                  <ImportXeroSuppliers onTaskSuccess={refreshData}/>
                </div>
                <ImportXeroProducts onTaskSuccess={refreshData} />
              </div>
            </div>

            <div className="mt-4">
              <div className="flex flex-row justify-between">
                <SubTitle name={"Import Data From Xero Tasks"} />
                <RefreshButton
                  name="Refresh Task Status"
                  action={refreshData}
                />
              </div>
            </div>
            {loading ? (
              <Loader mess="Requesting data, please wait a moment..." />
            ) : success ? (
              <div>
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      {/* <th></th> */}
                      <th>Date</th>
                      <th>Type</th>
                      <th>Operator</th>
                      <th>Status</th>
                      <th>Sucess Product Count</th>
                      <th>Errors</th>
                    </tr>
                  </thead>
                  <tbody>
                    {xeroImportTasks.tasks.length > 0 ? (
                      xeroImportTasks.tasks.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <ViewDateWithTime value={item.atime} />
                          </td>
                          <td>Import {item.type} from Xero</td>
                          <td>
                            {item.operatorFirstName} {item.operatorLastName}{" "}
                          </td>
                          <td>
                            {item.status === "QUEUED" ? (
                              <Tag name={item.status} color="yellow" />
                            ) : item.status === "PROCESSING" ? (
                              <Tag name={item.status} color="green" />
                            ) : item.status === "SUCCEED" ? (
                              <Tag name={item.status} color="blue" />
                            ) : item.status === "FAILED" ? (
                              <Tag name={item.status} color="red" />
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>{item.success_cnt}</td>
                          <td>
                            {item.error_notes && item.error_notes.failedList && item.error_notes.failedList.length ? 
                              <ImportXeroDataErrors errors={item.error_notes.failedList} />: <></>}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>There are no import records.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {xeroImportTasks.tasks.length > 0 ? (
                  <Pagination
                    totalPageNum={xeroImportTasks.totalPages}
                    forcePage={pageNum}
                    onPageChange={onPageChangeRequest}
                  />
                ) : (
                  <></>
                )}
              </div>
            ) : error ? (
              <ErrorMessage mess={error} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
