import React, { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { IoIosMore } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { SubTitle } from "../../components/viewComponents";

export default function ProductMoreActions({}) {
  const [filterOpen, setFilterOpen] = useState(false);

  return (
    <div className="relative">
      <div
        className="w-[44px] h-[44px] ml-3  rounded flex items-center justify-center rounded border border-solid border-brandColor hover:bg-brandColor hover:text-white hover:cursor-pointer"
        onClick={() => setFilterOpen(!filterOpen)}
      >
        <IoIosMore className="w-4 h-4" />
      </div>

      <div
        className={`${
          filterOpen ? "absolute block top-14 right-0 z-20 " : "hidden"
        }  min-w-[280px] bg-white shadow-cardShadow p-4 rounded`}
      >
        <SubTitle name={"More Actions"} />
        <div className="w-full mt-4">
          <NavLink
            to={"/inventory/bulk-update"}
            className={
              "h-11 px-3 mt-2 flex items-center text-brandColor rounded border border-solid border-brandColor hover:bg-brandColor hover:text-white"
            }
          >
            <AiFillEdit />
            <span className="ml-1">Update products by bulk</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
