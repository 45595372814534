import React from "react";
import { MdArrowForward } from "react-icons/md";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ScreenTitle } from "../../components/editComponents";
import { ErrorMessage, Loader } from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import reportStyle from "./reportStyle.module.css";

export default function ReportingScreen() {
  useDocumentTitle("Reports");

  const {
    userDetails,
    success: userDetailsSuccess,
    loading: userDetailsLoading,
    error: userDetailsError,
  } = useSelector((state) => state.userDetail);

  const SectionTitle = ({ name }) => {
    return <p style={{ fontWeight: "700" }}>{name}</p>;
  };

  const ReportLink = ({ url, name }) => {
    return (
      <div className="min-w-[160px] flex items-center flex-row">
        <NavLink to={url} className="text-brandColor  hover:underline hover:cursor-pointer">
          {name}
        </NavLink>
      </div>
    );
  };

  const ReportNameLink = ({ url, name }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <NavLink to={url} className={reportStyle.reportNameLink}>
          {name}
        </NavLink>
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className={commonStyle.pageCardContentContainer}>
        {userDetailsLoading ? (
          <Loader mess="Requesting user details..." />
        ) : userDetailsSuccess ? (
          <div style={{ width: "100%" }}>
            <ScreenTitle title="Reporting" />
            <div className={reportStyle.section_wrapper}>
              <SectionTitle name="Inventory" />
              <div style={{ marginTop: "16px" }}>
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Report Name</th>
                      <th>Description</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {userDetailsSuccess ? (
                      userDetails.data.permissions.includes(
                        "inv_report_stock_on_hand"
                      ) ? (
                        <tr>
                          <td>INV1</td>
                          <td>
                            <ReportNameLink
                              url={"/reporting/inventory/stock-report"}
                              name="Stock on Hand Report"
                            />
                          </td>
                          <td>
                            A report showing a breakdown and total cost of
                            currenct stock on hand, optionally filtered by
                            location and category.
                          </td>

                          <td>
                            <ReportLink
                              url={"/reporting/inventory/stock-report"}
                              name="View & Dowonload Report"
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    {userDetailsSuccess ? (
                      userDetails.data.permissions.includes(
                        "inv_report_stocktake"
                      ) ? (
                        <tr>
                          <td>INV2</td>
                          <td>
                            <ReportNameLink
                              url={"/reporting/inventory/stocktake-report"}
                              name="Stocktake Report"
                            />
                          </td>
                          <td>
                            A report displaying products with current inventory
                            levels to support a manual inventory count,
                            optionally filtered by location and category.
                          </td>

                          <td>
                            <ReportLink
                              url={"/reporting/inventory/stocktake-report"}
                              name="View & Dowonload Report"
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    {userDetailsSuccess ? (
                      userDetails.data.permissions.includes(
                        "inv_report_stock_alert"
                      ) ? (
                        <tr>
                          <td>INV3</td>
                          <td>
                            <ReportNameLink
                              url={"/reporting/inventory/stock-alert-report"}
                              name="Stock Alert Report"
                            />
                          </td>
                          <td>
                            A report displaying products with stock level below
                            re-order level, optionally filtered by location and
                            category.
                          </td>

                          <td>
                            <ReportLink
                              url={"/reporting/inventory/stock-alert-report"}
                              name="View & Dowonload Report"
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    {userDetailsSuccess ? (
                      userDetails.data.permissions.includes(
                        "inv_report_products"
                      ) ? (
                        <tr>
                          <td>INV4</td>
                          <td>
                            <ReportNameLink
                              url={"/reporting/inventory/product-list-report"}
                              name="Products Report"
                            />
                          </td>
                          <td>
                            A report displaying all the products (with
                            variations) in the system,optionally filtered by
                            category.
                          </td>

                          <td>
                            <ReportLink
                              url={"/reporting/inventory/product-list-report"}
                              name="View & Dowonload Report"
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    {userDetailsSuccess ? (
                      userDetails.data.permissions.includes(
                        "inv_report_stock_adjust"
                      ) ? (
                        <tr>
                          <td>INV5</td>
                          <td>
                            {" "}
                            <ReportNameLink
                              url={
                                "/reporting/inventory/stock-adjustment-report"
                              }
                              name="Stock Adjustment Report"
                            />
                          </td>
                          <td>
                            A report displaying all the history stock adjustment
                            record, optionally filtered by location, category
                            and create date.
                          </td>

                          <td>
                            <ReportLink
                              url={
                                "/reporting/inventory/stock-adjustment-report"
                              }
                              name="View & Dowonload Report"
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    {userDetailsSuccess ? (
                      userDetails.data.permissions.includes(
                        "inv_report_stock_transfer"
                      ) ? (
                        <tr>
                          <td>INV6</td>
                          <td>
                            <ReportNameLink
                              url={"/reporting/inventory/stock-transfer-report"}
                              name="Stock Transfer Report"
                            />
                          </td>
                          <td>
                            A report displaying all the history stock transfer
                            record, optionally filtered by location, category
                            and create date.
                          </td>

                          <td>
                            <ReportLink
                              url={"/reporting/inventory/stock-transfer-report"}
                              name="View & Dowonload Report"
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    {userDetailsSuccess ? (
                      userDetails.data.permissions.includes(
                        "inv_report_serial_batch_report"
                      ) ? (
                        <tr>
                          <td>INV7</td>
                          <td>
                            <ReportNameLink
                              url={"/reporting/inventory/serial-batch-report"}
                              name="Serial & Batch Numbers Report"
                            />
                          </td>
                          <td>
                            A report displaying all the available serial and
                            batch numbers in the system, with the option to
                            filter by product name, category, and location.
                          </td>

                          <td>
                            <ReportLink
                              url={"/reporting/inventory/serial-batch-report"}
                              name="View & Dowonload Report"
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className={reportStyle.section_wrapper}>
              <SectionTitle name="Sale" />
              <div style={{ marginTop: "16px" }}>
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Report Name</th>
                      <th>Description</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {userDetailsSuccess ? (
                      userDetails.data.permissions.includes(
                        "sale_report_summary"
                      ) ? (
                        <tr>
                          <td>SALE1</td>
                          <td>
                            <ReportNameLink
                              url={"/reporting/sale/summary"}
                              name="Sales Summary Report"
                            />
                          </td>
                          <td>
                            A report showing a sales summary of sale orders,
                            optionally filtered by order date, customer, sales
                            person and location
                          </td>
                          <td>
                            <ReportLink
                              url={"/reporting/sale/summary"}
                              name="View & Dowonload Report"
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    {userDetailsSuccess ? (
                      userDetails.data.permissions.includes(
                        "sale_report_gross_profit"
                      ) ? (
                        <tr>
                          <td>SALE2</td>
                          <td>
                            <ReportNameLink
                              url={"/reporting/sale/gross-profit"}
                              name="Sales Gross Profit Report"
                            />
                          </td>
                          <td>
                            A report that summarizes sales and shows the gross
                            profit for each order in a given period
                          </td>

                          <td>
                            <ReportLink
                              url={"/reporting/sale/gross-profit"}
                              name="View & Dowonload Report"
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}

                   
                    {userDetailsSuccess ? (
                      userDetails.data.permissions.includes(
                        "sale_report_by_product_summary"
                      ) ? (
                        <tr>
                          <td>SALE3</td>
                          <td>
                            <ReportNameLink
                              url={"/reporting/sale/summary-by-product"}
                              name="Sale Summary by Product Report"
                            />
                          </td>
                          <td>
                            A report showing a sales summary in a given period,
                            optionally group by sales person, category and
                            location
                          </td>
                          <td>
                            <ReportLink
                              url={"/reporting/sale/summary-by-product"}
                              name="View & Dowonload Report"
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                     {userDetailsSuccess ? (
                      userDetails.data.permissions.includes(
                        "sale_report_gross_profit_by_product_summary"
                      ) ? (
                        <tr>
                          <td>SALE4</td>
                          <td>
                            <ReportNameLink
                              url={"/reporting/sale/profit-by-product"}
                              name="Sales Gross Profit by Product Report"
                            />
                          </td>
                          <td>
                            A report showing a sales summary in a given period,
                            optionally group by sales person, category and
                            location
                          </td>
                          <td>
                            <ReportLink
                              url={"/reporting/sale/profit-by-product"}
                              name="View & Dowonload Report"
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className={reportStyle.section_wrapper}>
              <SectionTitle name="Purchase" />
              <div style={{ marginTop: "16px" }}>
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Report Name</th>
                      <th>Description</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {userDetailsSuccess ? (
                      userDetails.data.permissions.includes(
                        "purchase_report_summary"
                      ) ? (
                        <tr>
                          <td>PUR1</td>
                          <td>
                            <ReportNameLink
                              url={"/reporting/purchase/summary"}
                              name="Purchase Order Summary Report"
                            />
                          </td>
                          <td>
                            A report showing a summary of purchase orders,
                            optionally filtered by order date, supplier, creator and location
                          </td>
                          <td>
                            <ReportLink
                              url={"/reporting/purchase/summary"}
                              name="View & Dowonload Report"
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}

                  </tbody>
                </table>
              </div>
            </div>

            <div className={reportStyle.section_wrapper}>
              <SectionTitle name="Project" />
              <div style={{ marginTop: "16px" }}>
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Report Name</th>
                      <th>Description</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {userDetailsSuccess ? (
                      userDetails.data.permissions.includes(
                        "products_summary_by_project"
                      ) ? (
                        <>
                        <tr>
                          <td>PRO1</td>
                          <td>
                            <ReportNameLink
                              url={"/reporting/project/products-summary"}
                              name="Products Summary by Project Report"
                            />
                          </td>
                          <td>
                            A report showing a summary of products by project,
                            optionally filtered by project
                          </td>
                          <td>
                            <ReportLink
                              url={"/reporting/project/products-summary"}
                              name="View & Dowonload Report"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>PRO2</td>
                          <td>
                            <ReportNameLink
                              url={"/reporting/project/product-transactions"}
                              name="Product Transactions by Project Report"
                            />
                          </td>
                          <td>
                            A report showing product transactions by project,
                            optionally filtered by project, location and date
                          </td>
                          <td>
                            <ReportLink
                              url={"/reporting/project/product-transactions"}
                              name="View & Dowonload Report"
                            />
                          </td>
                        </tr>
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}

                  </tbody>
                </table>
              </div>
            </div>

            <p style={{marginLeft:"20px"}}>*Need a tailored report? Reach out to us, and we'll create it for you.</p>
          </div>
        ) : userDetailsError ? (
          <ErrorMessage mess={userDetailsError} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
